<template>
  <div id="weather-checker-container">

    <div id="buttons-container">
      <div class="action-container">
        <button id="get-weather" class="flat-right" @click="getWeather()">Get Weather By City</button> 
        <input v-model="cities">
      </div>

      <div class="action-container">
        <button id="get-location" @click="getLocation()">Get Weather By Location</button>
      </div>
    </div>

    <div v-if="weather">
      
      <div id="mobile-header" class="boxed light title-box">
            <h3 class="heading">{{cityName}}</h3>
      </div>

      <div id="results" >
        
        <div class="section-container">
          <div class="boxed light title-box">
            <h3 class="heading">{{weather.description}}</h3>
          </div>

          <div id="visual-weather">
            <div id="temp-icon-container">
              <div id="temp-icon" class="boxed">
                <img :src="(weatherImageURL)"/>
                <p id="temp" class="boxed light"> {{temp}} °C</p>
              </div>
            </div>

            <div id="wind-container">
              <div id="wind-image-container" class="boxed">
                <img :style="computedWindStyles" :src="require('@/assets/images/icons/arrow.svg')"/>
              </div>
              <p class="boxed light"> {{wind.speed}} mph</p>
            </div>

          </div>

        </div>
        
        <div class="section-container">

          <div id="desktop-header" class="boxed light title-box">
            <h3 class="heading">{{cityName}}</h3>
          </div>

          <div id="details-box" class="boxed light">
            <p>Feels Like: {{convertTemp(otherDetails.feels_like)}} °C</p>
            <p>Max Temp: {{convertTemp(otherDetails.temp_max)}} °C</p>
            <p>Min Temp: {{convertTemp(otherDetails.temp_min)}} °C</p>
            <p>Pressure: {{otherDetails.pressure}}</p>
            <p>Humidity: {{otherDetails.humidity}}</p>
          </div>

        </div>

      </div>
    
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'WeatherChecker',
  components: {},
  data() {
    return {
      weather: null,
      cities: 'London',
      temp: null,
      wind: null,
      geolocationStatus: null,
      coordinates: {
        longitude: null,
        latitude: null,
      },
      otherDetails: null,
      cityName: null,
    }
  },
  methods: {
    convertTemp(temp) {
      return (temp - 273.15).toFixed(1);
    },
    getWeather(searchType = 'city') {
      const baseURL = 'https://api.openweathermap.org/data/2.5/weather?';
      const queryParams = ['appid=27cefcf69235c0292e2fe0aa2e772dcc'];
      if (searchType === 'city') {
        queryParams.push(`q=${this.cities}`);
      } else if (searchType === 'co-ordinates') {
        queryParams.push(`lat=${this.coordinates.latidute}`);
        queryParams.push(`lon=${this.coordinates.longitude}`);
      }
      fetch(`${baseURL}${queryParams.join('&')}`)
        .then(response => response.json())
        .then(data => {
          this.temp = (this.convertTemp(data.main.temp));
          this.weather = data.weather[0];
          this.wind = data.wind;
          this.otherDetails = data.main;
          this.cityName = data.name;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getLocation() {
       if(!navigator.geolocation) {
          this.geolocationStatus = 'Geolocation is not supported by your browser';
      } else {
          this.geolocationStatus = 'Locating…';
          navigator.geolocation.getCurrentPosition(this.getLocationSuccess, this.getLocationFail);
      }
    },
    getLocationSuccess(position) {
      this.coordinates = {
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
      }
      this.getWeather('co-ordinates');
    },
    getLocationFail() {
       console.log('Fail')
    },
  },
  computed: {
    weatherImageURL() {
      return this.weather ? require(`@/assets/images/weather/${this.weather.icon}@2x.png`): '';
    },
    computedWindStyles() {
      let scale = this.wind.speed === 0 ? 0 : 0.4 + (this.wind.speed * 0.05);
      if (scale > 1) scale = 1;
      return `transform: scale(${scale}) rotate(${this.wind.deg - 90}deg)`;
    }
  },
}
</script>

<style lang="scss" scoped>

$mobile-width: 520px;
$side-space: 10px;

#weather-checker-container {
  background: linear-gradient(to bottom right, #0088cc, #e6f3ff);
  padding-bottom: 10px;
  .site-heading {
    margin: auto;
    margin-top: 15px;
  }
}

#mobile-header {
  display: none;
  margin-left: $side-space;
  margin-right: $side-space;

  @media only screen and (max-width: $mobile-width) {
    display: block;
  }
}

#desktop-header {
  @media only screen and (max-width: $mobile-width) {
    display: none;
  }
}

#buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

#results {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $mobile-width) {
    flex-direction: column;
  }
}

#visual-weather {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

#temp-icon-container {
  position: relative;
}

#temp-icon {
  $size: 150px;
  width: $size;
  height: $size;
  position: relative;

  img {
    width: 100%;
    margin-top: -20px;
    
  }
}

#temp {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 7px;
  border-bottom: none;
  border-right: none;
  border-bottom-left-radius: unset;
  border-top-right-radius: unset;
}


#wind-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 15px;
}

#wind-image-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 3px;
  }
}

#details-box {
  $spacing: 10px;
  padding-left: $spacing;
  padding-right: $spacing;
  flex-grow: 1;

  p {
    text-align: left;
    margin: 2px;

    &:nth-child(even) {
      font-weight: bold;
    }
  }
}

.action-container {
  margin: 10px 30px;
  text-align: center;
}

.boxed {
  background-color: #8FAADC;
  border: 3px solid black;
  border-radius: 4px;
  margin: 0;
  text-transform: capitalize;
  padding: 5px;
  
  .light {
    background-color: white;
  }

  &.title-box {
    margin-bottom: 20px;
   
    @media only screen and (max-width: $mobile-width) {
      h3 {
        text-align: center;
        width: 100%;
      }
    }
  }
}

.section-container {
  margin-left: $side-space;
  margin-right: $side-space;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
}

button {
  $boarder-radius: 5px;
  border-radius: $boarder-radius;
  background-color: #013547;
  color:  #ffffff;

  &.flat-right {
    border-radius: $boarder-radius 0 0 $boarder-radius;
  }
}

.light {
  background-color: #ffffff;
  color: #013547;
}
</style>