<template>
    
<div>
  <ProjectPageTemplate
    projectTitle="WEATHER CHECKER"
    :imageSource="require('@/assets/images/projectLinks/weatherLink3.png')"
    shortDescription="SUITABLE FOR MOBILE - Using a free source weather API either enter a city name to check its weather, or location service permitting, check the weather in your local area"
    longDescription="This was written in JavaScript and HTML. I used this project to work with API calls and using different query params for either an entered city name or using the device's location to request various weather-related data. The returned values were then formatted and displayed as text or visualised as images. This was project I found useful for learning to format page elements to display on different screen sizes and on mobile."
    :importedLinkData="techLinksData"
  >
  <template v-slot:main-content>
    <WeatherChecker />
  </template>
  </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import WeatherChecker from '@/components/WeatherChecker.vue';
import techLinks from '@/data/techLinks.js'
export default {
  name: 'weatherPage',
  components: {
    ProjectPageTemplate,
    WeatherChecker,
  },
  data() {
    return {
      techLinksData: [
        techLinks.html, 
        techLinks.javascript,
        techLinks.css
      ],
    }
  }
}
</script>